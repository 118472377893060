<template>
  <b-tabs v-model="tabSelected" pills>
    <b-tab title="Facturas">
      <BaseCard
        :caption-visible="false"
        :footer-visible="false"
        actions-visible
      >
        <template #title>
          <span class="h3 font-weight-bold mb-0 text-uppercase">facturas proveedores</span>
        </template>
        <template #actions>
          <b-link
            v-access="{
              resource: $data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICES,
              resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
            }"
            :to="{ name: 'createPurchaseInvoice' }"
          >
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
            >
              + Añadir factura proveedor
            </b-button>
          </b-link>
        </template>
        <PurchaseInvoicesTable :totals-configuration="totalsConfiguration" :origin="'invoice'"/>
      </BaseCard>
    </b-tab>
    <b-tab title="Fra. No asociadas a Job">
      <BaseCard
        :caption-visible="false"
        :footer-visible="false"
        actions-visible
      >
        <template #title>
          <span class="h3 font-weight-bold mb-0 text-uppercase">fra. no asociadas a job</span>
        </template>
        <template #actions>
          <b-link
            v-access="{
              resource: $data.$constants.RESOURCES.RESOURCE_PURCHASE_INVOICES,
              resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
            }"
            :to="{ name: 'createPurchaseInvoice' }"
          >
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
            >
              + Añadir factura proveedor
            </b-button>
          </b-link>
        </template>
        <PurchaseInvoicesTable
          :with-job-mode="false"
          :totals-configuration="totalsConfigurationsWithJobModeDisabled"
          :origin="'fra'"
        />
      </BaseCard>
    </b-tab>
  </b-tabs>
</template>

<script>
import PurchaseInvoicesTable from '@/components/purchase-invoices/table/PurchaseInvoicesTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'List',
  components: { BaseCard, PurchaseInvoicesTable },
  data() {
    return {
      totalsConfiguration: [
        { colspan: 7 },
        { label: 'TOTAL BRUTO', key: 'total_gross', unit: '€' },
        { label: 'TOTAL RETENCIÓN', key: 'total_retention', unit: '€' },
        { label: "TOTAL LÍQUIDO", key: "total_liquid", unit: '€' },
        { label: 'TOTAL', key: 'total_invoice', unit: '€' },
        { colspan: 2 },
      ],
      totalsConfigurationsWithJobModeDisabled: [
        { colspan: 6 },
        { label: 'TOTAL BRUTO', key: 'total_gross', unit: '€' },
        { label: 'TOTAL RETENCIÓN', key: 'total_retention', unit: '€' },
        { label: "TOTAL LÍQUIDO", key: "total_liquid", unit: '€' },
        { label: 'TOTAL', key: 'total_invoice', unit: '€' },
        { colspan: 2 },
      ],
    }
  },
  computed: {
    ...mapGetters({
      tab: 'purchaseInvoice/getTabSelected',
    }),
    tabSelected: {
      get () {
        return this.tab
      },
      set (value) {
        // when change tab clear filter for prevent incompatible filtering
        // this.$store.commit('purchaseInvoice/setFilters', {})
        this.$store.commit('purchaseInvoice/setTabSelected', value)
      },
      immediate: true
    }
  },
  beforeMount() {
    /* el tabSelected define las vistas que se cargaran en el editar
      y en el crear y los filtros que se guardan en el store
        - 0 para facturas con job
        - 1 para facturas sin job
      se resetea al entrar a la vista para evitar que se quede guardado
      el tab seleccionado
      relacionado con el bug de views/orders
    */
    // this.$store.commit('purchaseInvoice/setTabSelected', 0)
  },
}
</script>
